import { CompanyAction, CompanyState } from "./company-interface";
import {
  CompanyDataResponseDTO,
  CompanyDetailsDTO,
  CompanyInfoDTO,
  GetCompanyDataResponse,
  NewEsgCertificateDTO,
  EsgCertificateListInfoDTO,
} from "../../model/CompanyDTO";
import { getNested } from "../../utils/commonUtils";
import { InvoiceHistory, NewCustomerHistory } from "../../model/AdminDTO";

export const GET_COMPANY_LIST_REQUEST = "getCompanyListRequest";
export const GET_COMPANY_LIST_SUCCESS = "getCompanyListSuccess";
export const GET_COMPANY_LIST_FAIL = "getCompanyListFail";
export const GET_COMPANY_DATA_REQUEST = "getCompanyDataRequest";
export const GET_COMPANY_DATA_SUCCESS = "getCompanyDataSuccess";
export const GET_COMPANY_DATA_FAIL = "getCompanyDataFail";
export const GET_COMPANY_MONEYLENDER_REQUEST =
  "getCompanyForMoneylenderRequest";
export const GET_COMPANY_MONEYLENDER_SUCCESS =
  "getCompanyForMoneylenderSuccess";
export const GET_COMPANY_MONEYLENDER_FAIL = "getCompanyForMoneylenderFail";
export const SET_COMPANY_DETAILS_REQUEST = "setCompanyDataRequest";
export const SET_COMPANY_DETAILS_SUCCESS = "setCompanyDataSuccess";
export const SET_COMPANY_DETAILS_FAIL = "setCompanyDataFail";
export const SWAP_USER_REQUEST = "swapUserRequest";
export const SWAP_USER_SUCCESS = "swapUserSuccess";
export const SWAP_USER_FAIL = "swapUserFail";
export const SET_COMPANY_ID = "setCompanyId";
export const SET_BUSINESS_NAME = "setBusinessName";
export const ADD_LEGAL_REPRESENTATIVE = "addLegalRepresentativeRequest";
export const ADD_LEGAL_REPRESENTATIVE_SUCCESS = "addLegalRepresentativeSuccess";
export const ADD_LEGAL_REPRESENTATIVE_FAIL = "addLegalRepresentativeFail";
export const REMOVE_EFFECTIVE_OWNER = "removeEffectiveOwnerRequest";
export const REMOVE_EFFECTIVE_OWNER_SUCCESS = "removeEffectiveOwnerSuccess";
export const REMOVE_EFFECTIVE_OWNER_FAIL = "removeEffectiveOwnerFail";
export const REMOVE_LEGAL = "removeLegalRepresentativeRequest";
export const REMOVE_LEGAL_SUCCESS = "removeLegalRepresentativeSuccess";
export const REMOVE_LEGAL_FAIL = "removeLegalRepresentativeFail";
export const EMPTY_ADD_USER_ERROR = "emptyAddLegalError";
export const GET_COMPANY_INFO = "getCompanyInfo";
export const GET_COMPANY_INFO_SUCCESS = "getCompanyInfoSuccess";
export const GET_COMPANY_INFO_FAIL = "getCompanyInfoFail";
export const GET_NEW_VENDORS = "getNewVendors";
export const GET_NEW_VENDORS_SUCCESS = "getNewVendorsSuccess";
export const GET_NEW_VENDORS_FAIL = "getNewVendorsFail";
export const GET_MARKET_DATA = "getMarketData";
export const GET_MARKET_DATA_SUCCESS = "getMarketDataSuccess";
export const GET_MARKET_DATA_FAIL = "getMarketDataFail";
export const RESET_COMPANY = "resetCompany";
export const GET_NEW_PROGRAMS = "getNewPrograms";
export const GET_NEW_PROGRAMS_SUCCESS = "getNewProgramsSuccess";
export const GET_NEW_PROGRAMS_FAIL = "getNewProgramsFail";
export const GET_INVOICE_HISTORY = "getInvoiceHistory";
export const GET_INVOICE_HISTORY_SUCCESS = "getInvoiceHistorySuccess";
export const GET_INVOICE_HISTORY_FAIL = "getInvoiceHistoryFail";
export const ADD_ESG_CERTIFICATE_REQUEST = "addEsgCertificateRequest";
export const ADD_ESG_CERTIFICATE_SUCCESS = "addEsgCertificateSuccess";
export const ADD_ESG_CERTIFICATE_FAIL = "addEsgCertificateFail";
export const GET_ESG_CERTIFICATE= "getEsgCertificate";
export const GET_ESG_CERTIFICATE_SUCCESS= "getEsgCertificateSuccess";
export const GET_ESG_CERTIFICATE_FAIL= "getEsgCertificateFail";
export const SET_ESG_CERTIFICATE = "setEsgCertificate"
export const SET_ESG_CERTIFICATE_SUCCESS = "setEsgCertificateSuccess"
export const SET_ESG_CERTIFICATE_FAIL = "setEsgCertificateFail"

const defaultState: CompanyState = {
    isFetchingGetCompanyList: false,
    companyList: [],
    getCompanyListError: "",
    isFetchingGetCompanyData: false,
    getCompanyDataResponse: {} as GetCompanyDataResponse,
    getCompanyDataError: "",
    isFetchingSwapUser: false,
    swapUserResponse: null,
    swapUserError: "",
    isFetchingSetCompanyDetails: false,
    isFetchingGetBuyersForMoneylender: false,
    getBuyersForMoneylenderResponse: [] as CompanyInfoDTO[],
    setCompanyDetailsResponse: {} as CompanyDetailsDTO,
    setCompanyDetailsError: "",
    isFetchingAddLegalRepresentative: false,
    addLegalRepresentativeResponse: "",
    addLegalRepresentativeError: "",
    isFetchingRemoveEffectiveOwner: false,
    removeEffectiveOwnerSuccess: "",
    removeEffectiveOwnerFail: "",
    isFetchingRemoveLegalRepresentative: false,
    removeLegalRepresentativeSuccess: "",
    removeLegalRepresentativeFail: "",
    companyId: "",
    businessName: "",
    isFetchingGetCompanyInfo: false,
    getCompanyInfoResponse: null,
    getCompanyInfoFail: "",
    isFetchingGetNewVendors: null,
    getNewVendorsSuccess: {},
    getNewVendorsFail: "",
    isFetchingGetMarketData: null,
    getMarketDataSuccess: [],
    getMarketDataFail: "",
    isFetchingNewPrograms: null,
    newProgramsResponse: {} as NewCustomerHistory,
    newProgramsFail: "",
    isFetchingGetInvoiceHistory: null,
    invoiceHistoryResponse: {} as InvoiceHistory,
    invoiceHistoryFail: "",
    isFetchingAddEsgCertificate: false,
    addEsgCertificateResponse: {} as NewEsgCertificateDTO,
    addEsgCertificateError: "",
    isFetchingGetEsgCertificate: null,
    esgCertificate: [] as EsgCertificateListInfoDTO[],
    getEsgCertificateFail: "",
    isFetchingSetEsgCertificate: false,
    setEsgCertificateResponse: false,
    setEsgCertificateError: "",
};

export function companyReducer(
  state = defaultState,
  action: CompanyAction
): CompanyState {
  switch (action.type) {
    case GET_COMPANY_LIST_REQUEST:
      return {
        ...state,
        companyList: [],
        getCompanyListError: "",
        isFetchingGetCompanyList: true,
      };
    case GET_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        companyList: action.payload,
        getCompanyListError: "",
        isFetchingGetCompanyList: false,
      };
    case GET_COMPANY_LIST_FAIL:
      return {
        ...state,
        companyList: [],
        getCompanyListError: action.payload,
        isFetchingGetCompanyList: false,
      };
    case SWAP_USER_REQUEST:
      return {
        ...state,
        swapUserResponse: [],
        swapUserError: "",
        isFetchingSwapUser: true,
      };
    case SWAP_USER_SUCCESS:
      return {
        ...state,
        swapUserResponse: action.payload,
        swapUserError: "",
        isFetchingSwapUser: false,
      };
    case SWAP_USER_FAIL:
      return {
        ...state,
        swapUserResponse: [],
        swapUserError: action.payload,
        isFetchingSwapUser: false,
      };
    case GET_COMPANY_DATA_REQUEST:
      return {
        ...state,
        getCompanyDataResponse: {} as GetCompanyDataResponse,
        getCompanyDataError: "",
        isFetchingGetCompanyData: true,
      };
    case GET_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        getCompanyDataResponse: action.payload,
        getCompanyDataError: "",
        isFetchingGetCompanyData: false,
      };
    case GET_COMPANY_DATA_FAIL:
      return {
        ...state,
        getCompanyDataResponse: {} as GetCompanyDataResponse,
        getCompanyDataError: action.payload,
        isFetchingGetCompanyData: false,
      };
    case GET_COMPANY_MONEYLENDER_REQUEST:
      return {
        ...state,
        getBuyersForMoneylenderResponse: [] as CompanyInfoDTO[],
        isFetchingGetBuyersForMoneylender: true,
      };
    case GET_COMPANY_MONEYLENDER_SUCCESS:
      return {
        ...state,
        getBuyersForMoneylenderResponse: action.payload,
        isFetchingGetBuyersForMoneylender: false,
      };
    case GET_COMPANY_MONEYLENDER_FAIL:
      return {
        ...state,
        getBuyersForMoneylenderResponse: [] as CompanyInfoDTO[],
        isFetchingGetBuyersForMoneylender: false,
      };
    case SET_COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingSetCompanyDetails: true,
        setCompanyDetailsResponse: {} as CompanyDetailsDTO,
        setCompanyDetailsError: "",
      };
    case SET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingSetCompanyDetails: false,
        setCompanyDetailsResponse: action.payload,
        companyId: getNested(action, "payload", "vatNumber"),
        setCompanyDetailsError: "",
      };
    case SET_COMPANY_DETAILS_FAIL:
      return {
        ...state,
        isFetchingSetCompanyDetails: false,
        setCompanyDetailsResponse: {} as CompanyDetailsDTO,
        setCompanyDetailsError: action.payload,
      };
    case SET_COMPANY_ID:
      return {
        ...state,
        companyId: action.payload,
      };
    case SET_BUSINESS_NAME:
      return {
        ...state,
        businessName: action.payload,
      };
    case ADD_LEGAL_REPRESENTATIVE:
      return {
        ...state,
        isFetchingAddLegalRepresentative: true,
        addLegalRepresentativeResponse: "",
        addLegalRepresentativeError: "",
      };
    case ADD_LEGAL_REPRESENTATIVE_SUCCESS:
      return {
        ...state,
        isFetchingAddLegalRepresentative: false,
        addLegalRepresentativeResponse: action.payload,
        addLegalRepresentativeError: "",
      };
    case ADD_LEGAL_REPRESENTATIVE_FAIL:
      return {
        ...state,
        isFetchingAddLegalRepresentative: false,
        addLegalRepresentativeResponse: "",
        addLegalRepresentativeError: action.payload,
      };
    case REMOVE_EFFECTIVE_OWNER:
      return {
        ...state,
        isFetchingRemoveEffectiveOwner: true,
        removeEffectiveOwnerSuccess: "",
        removeEffectiveOwnerFail: "",
      };
    case REMOVE_EFFECTIVE_OWNER_SUCCESS:
      return {
        ...state,
        isFetchingRemoveEffectiveOwner: false,
        removeEffectiveOwnerSuccess: action.payload,
        removeEffectiveOwnerFail: "",
      };
    case REMOVE_EFFECTIVE_OWNER_FAIL:
      return {
        ...state,
        isFetchingRemoveEffectiveOwner: false,
        removeEffectiveOwnerSuccess: "",
        removeEffectiveOwnerFail: action.payload,
      };
    case REMOVE_LEGAL:
      return {
        ...state,
        isFetchingRemoveLegalRepresentative: true,
        removeLegalRepresentativeSuccess: "",
        removeLegalRepresentativeFail: "",
      };
    case REMOVE_LEGAL_SUCCESS:
      return {
        ...state,
        isFetchingRemoveLegalRepresentative: false,
        removeLegalRepresentativeSuccess: action.payload,
        removeLegalRepresentativeFail: "",
      };
    case REMOVE_LEGAL_FAIL:
      return {
        ...state,
        isFetchingRemoveLegalRepresentative: false,
        removeLegalRepresentativeSuccess: "",
        removeLegalRepresentativeFail: action.payload,
      };

    case GET_COMPANY_INFO:
      return {
        ...state,
        isFetchingGetCompanyInfo: true,
        getCompanyInfoResponse: null,
        getCompanyInfoFail: "",
      };
    case GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        isFetchingGetCompanyInfo: false,
        getCompanyInfoResponse: action.payload as CompanyDataResponseDTO,
        getCompanyInfoFail: "",
      };
    case GET_COMPANY_INFO_FAIL:
      return {
        ...state,
        isFetchingGetCompanyInfo: false,
        getCompanyInfoResponse: null,
        getCompanyInfoFail: action.payload,
      };

    case GET_NEW_VENDORS:
      return {
        ...state,
        isFetchingGetNewVendors: true,
        getNewVendorsSuccess: null,
        getNewVendorsFail: "",
      };
    case GET_NEW_VENDORS_SUCCESS:
      return {
        ...state,
        isFetchingGetNewVendors: false,
        getNewVendorsSuccess: action.payload,
        getNewVendorsFail: "",
      };
    case GET_NEW_VENDORS_FAIL:
      return {
        ...state,
        isFetchingGetNewVendors: false,
        getNewVendorsSuccess: null,
        getNewVendorsFail: action.payload,
      };

    case GET_MARKET_DATA:
      return {
        ...state,
        isFetchingGetMarketData: true,
        getMarketDataSuccess: null,
        getMarketDataFail: "",
      };
    case GET_MARKET_DATA_SUCCESS:
      return {
        ...state,
        isFetchingGetMarketData: false,
        getMarketDataSuccess: action.payload,
        getMarketDataFail: "",
      };
    case GET_MARKET_DATA_FAIL:
      return {
        ...state,
        isFetchingGetMarketData: false,
        getMarketDataSuccess: null,
        getMarketDataFail: action.payload,
      };

    case GET_NEW_PROGRAMS:
      return {
        ...state,
        isFetchingNewPrograms: true,
        newProgramsResponse:
          defaultState.newProgramsResponse as NewCustomerHistory,
        newProgramsFail: defaultState.newProgramsFail,
      };
    case GET_NEW_PROGRAMS_SUCCESS:
      return {
        ...state,
        isFetchingNewPrograms: false,
        newProgramsResponse: action.payload,
        newProgramsFail: defaultState.newProgramsFail,
      };
    case GET_NEW_PROGRAMS_FAIL:
      return {
        ...state,
        isFetchingNewPrograms: false,
        newProgramsResponse:
          defaultState.newProgramsResponse as NewCustomerHistory,
        newProgramsFail: action.payload,
      };
    case GET_INVOICE_HISTORY:
      return {
        ...state,
        isFetchingGetInvoiceHistory: true,
        invoiceHistoryResponse:
          defaultState.invoiceHistoryResponse as InvoiceHistory,
        invoiceHistoryFail: defaultState.invoiceHistoryFail,
      };
    case GET_INVOICE_HISTORY_SUCCESS:
      return {
        ...state,
        isFetchingGetInvoiceHistory: false,
        invoiceHistoryResponse: action.payload,
        invoiceHistoryFail: defaultState.invoiceHistoryFail,
      };
    case GET_INVOICE_HISTORY_FAIL:
      return {
        ...state,
        isFetchingGetInvoiceHistory: false,
        invoiceHistoryResponse:
          defaultState.invoiceHistoryResponse as InvoiceHistory,
        invoiceHistoryFail: action.payload,
      };

    case RESET_COMPANY:
      return {
        ...state,
        ...defaultState,
      };

    case EMPTY_ADD_USER_ERROR:
      return {
        ...state,
        addLegalRepresentativeError: "",
      };
    case ADD_ESG_CERTIFICATE_REQUEST:
      return {
        ...state,
        isFetchingAddEsgCertificate: true,
        addEsgCertificateResponse: {} as NewEsgCertificateDTO,
        addEsgCertificateError: "",
      };
    case ADD_ESG_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isFetchingAddEsgCertificate: false,
        addEsgCertificateResponse: action.payload as NewEsgCertificateDTO,
        addEsgCertificateError: "",
      };
    case ADD_ESG_CERTIFICATE_FAIL:
      return {
        ...state,
        isFetchingAddEsgCertificate: false,
        addEsgCertificateResponse: {} as NewEsgCertificateDTO,
        addEsgCertificateError: action.payload,
      };
    case GET_ESG_CERTIFICATE:
      return {
        ...state,
        isFetchingGetEsgCertificate: true,
        esgCertificate: [],
        getEsgCertificateFail: "",
      };
    case GET_ESG_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isFetchingGetEsgCertificate: false,
        esgCertificate: action.payload,
        getEsgCertificateFail: "",
      };

    case GET_ESG_CERTIFICATE_FAIL:
      return {
        ...state,
        isFetchingGetEsgCertificate: false,
        esgCertificate: [],
        getEsgCertificateFail: action.payload,
      };
    case SET_ESG_CERTIFICATE:
      return {
        ...state,
        isFetchingSetEsgCertificate: true,
        setEsgCertificateResponse: false,
        setEsgCertificateError: "",
      };
    case SET_ESG_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isFetchingSetEsgCertificate: false,
        setEsgCertificateResponse: action.payload,
        setEsgCertificateError: "",
      };
    case SET_ESG_CERTIFICATE_FAIL:
      return {
        ...state,
        isFetchingSetEsgCertificate: false,
        setEsgCertificateResponse: false,
        setEsgCertificateError: action.payload,
      };
    default:
      return state;
  }
}
