export {companyReducer} from './company-reducers';

export {
    getCompanyData,
    setCompanyDetails,
    addLegalRepresentative,
    deleteEffectiveOwner,
    getAllBuyersForMoneylender,
    getCompanyList,
    addEsgCertificate
} from './company-actions';
