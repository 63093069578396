import {networkService} from "./NetworkService";
import {MyResponse} from "../model/ServiceDTO";
import {
    CompanyDetailsDTO,
    CompanyDTO,
    CompanyInfoDTO,
    DeleteEffectiveOwnerRequest,
    GetCompanyDataDTORequest,
    GetCompanyDataResponse,
    NewEsgCertificateDTO,
    UpdateLegalRepresentativeEffectiveOwnerRequest,
    VendorBuyerAssociated,
    EsgCertificateListInfoDTO,
    EsgCertificateApprovalDTO,
    ESGCertificateFileDTO
} from "../model/CompanyDTO";
import {configService} from "./ConfigService";
import {FileToDownloadDTO, InvoiceHistory, ModifyCompanyDTO, ModifyUserDTO, NewCustomerHistory, SwapUserRequestDTO} from "../model/AdminDTO";
import {Roles} from "../model/UserDTO";

const BASE_URL = configService.getApiPath();

class CompanyService {

    getCompanyList = (role: Roles): Promise<MyResponse<[CompanyDTO]>> => {
        return networkService.get<[CompanyDTO]>(BASE_URL + "/company/role/" + role);
    };

    getCompanyData = (data: GetCompanyDataDTORequest): Promise<MyResponse<GetCompanyDataResponse>> => {
        return networkService.post<GetCompanyDataDTORequest, GetCompanyDataResponse>(BASE_URL + "/company", data);
    };

    setCompanyDetails = (vatNumber: string, data: CompanyDetailsDTO): Promise<MyResponse<CompanyDetailsDTO>> => {
        return networkService.post<CompanyDetailsDTO, CompanyDetailsDTO>(BASE_URL + `/company/${vatNumber}`, data);
    };

    addLegalRepresentative = (vatNumber: string, data: UpdateLegalRepresentativeEffectiveOwnerRequest) => {
        return networkService.post<UpdateLegalRepresentativeEffectiveOwnerRequest, any>(BASE_URL + `/company/legal/${vatNumber}`, data);
    };

    deleteLegalRepresentative = (vatNumber: string) => {
        return networkService.post<any, number>(BASE_URL + `/company/legalRepresentative/delete/${vatNumber}`, null);
    };

    deleteEffectiveOwner = (vatNumber: string, email: DeleteEffectiveOwnerRequest) => {
        return networkService.post<DeleteEffectiveOwnerRequest, number>(BASE_URL + `/company/effectiveOwner/delete/${vatNumber}`, email);
    };

    getAllBuyersForMoneylender = () => {
        return networkService.get<[CompanyInfoDTO]>(BASE_URL + "/company/moneylender/buyers");
    };

    getCompanyInfo = (vatNumber: string, userId?: string) => {
        return networkService.get<any>(`${BASE_URL}/company/companyData/${vatNumber}${userId ? `/${userId}` : ''}`);
    };

    getDocument = (file: FileToDownloadDTO) => {
        return networkService.post<any, any>(BASE_URL + `/company/companyData`, file);
    };

    getNewCustomers = (role?: Roles) => {
        return networkService.get<[string]>(BASE_URL + `/company/newCustomers${role ? ("/" + role) : ""}`);
    };

    getMarketData = () => {
        return networkService.get<[VendorBuyerAssociated]>(BASE_URL + `/company/vendors`);
    };

    getCompanyUsers = (vat?: string) => {
        return networkService.get<ModifyUserDTO[]>(`${BASE_URL}/company/users${vat ? `/${vat}` : ""}`);
    };

    getCompanies = () => {
        return networkService.get<ModifyCompanyDTO[]>(BASE_URL + `/company/companies`);
    };

    getNewPrograms = () => {
        return networkService.get<NewCustomerHistory>(BASE_URL + `/company/newProgram`);
    };

    getInvoiceHistory = () => {
        return networkService.get<InvoiceHistory>(BASE_URL + `/company/invoiceHistory`);
    };

    blockUser = (userId: string, vat: string): Promise<MyResponse<any>> => {
        return networkService.post<null, any>(`${BASE_URL}/company/blockUser/${vat}/${userId}`, null);
    };

    unblockUser = (userId: string, vat: string): Promise<MyResponse<any>> => {
        return networkService.post<null, any>(`${BASE_URL}/company/unblockUser/${vat}/${userId}`, null);
    };

    blockCompany = (vatNumber: string): Promise<MyResponse<any>> => {
        return networkService.post<null, any>(`${BASE_URL}/company/blockCompany/${vatNumber}`, null);
    };

    unblockCompany = (vatNumber: string): Promise<MyResponse<any>> => {
        return networkService.post<null, any>(`${BASE_URL}/company/unblockCompany/${vatNumber}`, null);
    };

    swapUser = (data: SwapUserRequestDTO): Promise<MyResponse<any>> => {
        return networkService.post<any, any>(`${BASE_URL}/company/changeLegalUser`, data);
    };

    addEsgCertificate = (data: NewEsgCertificateDTO): Promise<MyResponse<any>> => {
        return networkService.post<any, any>(`${BASE_URL}/company/esgCertificate`, data);
    }

    getEsgCertificate = () => {
        return networkService.get<[EsgCertificateListInfoDTO]>(BASE_URL + `/company/esgCertificate`);
    };

    dwonloadEsgCertificate = (id: any): Promise<MyResponse<ESGCertificateFileDTO>> => {
        return networkService.get<ESGCertificateFileDTO>(BASE_URL + "/company/esgCertificate/base64/" + id);
    };

    setApprovalCertificate = (data: EsgCertificateApprovalDTO): Promise<MyResponse<any>> => {
        return networkService.post<any, any>(`${BASE_URL}/company/approveEsgCertificate`, data);
    }

}

export const companyService = new CompanyService();
