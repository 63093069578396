import ScreenWrapper from "../../AdminComponent/ScreenWrapper/ScreenWrapper"
import * as React from 'react';
import {useTranslation} from "react-i18next";
import individual from "../../../assets/Images/individual.svg";
import NewUserCard from "../../../components/AdminComponent/NewUserCard/NewUserCard";
import "./ChooseUserDocumentsType.scss";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import { CompanyInfoSteps } from "../../../model/AdminDTO";
import { setCompanyInfoStep } from "../../../redux/admin/admin-actions";

const ChooseUserDocumentsType: React.FC<any> = ({setCompanyInfoStep}) =>{

    const {t} = useTranslation();


    return(
        <ScreenWrapper
            title={t("admin.chooseTypeOfUser.titleCompany")}
            description={t("admin.chooseTypeOfUser.description")}
        >
            <div className="chooseTypeOfUser">
                {/* <div className="chooseTypeOfUser__cardContainer">
                    <NewUserCard
                        styleCard="shadowCard"
                        title={t("admin.chooseTypeOfUser.buyer")}
                        icon={individual}
                        goToNextPage={() => inviteDispatcher(Roles.BUYER)}
                    />
                </div>                 */}
                <div className="chooseTypeOfUser__cardContainer">
                    <NewUserCard
                        styleCard="shadowCard"
                        title={t("supplier.addEsgCertificate.agency")}
                        icon={individual}
                        goToNextPage={() => setCompanyInfoStep(CompanyInfoSteps.ESG_CERTIFICATE_LIST)}
                    />
                </div>
                <div className="chooseTypeOfUser__cardContainer">
                    <NewUserCard
                        styleCard="shadowCard"
                        title={t("supplier.addEsgCertificate.users")}
                        icon={individual}
                        goToNextPage={() => setCompanyInfoStep(CompanyInfoSteps.USERS)}
                    />
                </div>
                <div className="chooseTypeOfUser__cardContainer">
                    <div className="chooseTypeOfUser__buttonPreviousContainer">

                    </div>
                </div>
            </div>
        </ScreenWrapper>
    );
};

const mapStateToProps = () => {
    return{};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        setCompanyInfoStep: (payload: CompanyInfoSteps) => dispatch(setCompanyInfoStep(payload))
    };
};

export default
connect(mapStateToProps, mapDispatchToProps)(ChooseUserDocumentsType);
