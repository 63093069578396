import * as React from "react";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import {
  CompanyInfoSteps,
  EsgConfigurationDTO,
  MimeTypes,
  RatingType,
} from "../../../model/AdminDTO";
import { setCompanyInfoStep } from "../../../redux/admin/admin-actions";
import ScreenWrapper from "../../AdminComponent/ScreenWrapper/ScreenWrapper";
import { getRatingESG } from "../../../redux/admin/admin-actions";
import CardContainerWithButtons from "../../Common/CardContainerWithButtons/CardContainerWithButtons";
import ContainerRow from "../../Common/ContainerRow/ContainerRow";
import { AppState } from "../../../redux/store";
import MyDropdown from "../../Common/MyDropdown/MyDropdown";
import { NewEsgCertificateDTO } from "../../../model/CompanyDTO";
import TextInput from "../../Common/TextInput/TextInput";
import DatePicker, {
  DatePickerTypes,
} from "../../Common/DatePicker/DatePicker";
import "./AddEsgCertificate.scss";
import CustomUploadFile from "../../Common/CustomUploadFile/CustomUploadFile";
import { addEsgCertificate } from "../../../redux/company";
import SignWrapper from "../../Common/SignersCombinations/SignWrapper";
import { ActionTypes } from "../../../model/InfoCertDTO";
import moment from "moment";

const AddEsgCertificate: React.FC<any> = ({
  onPrevious,
  esgConfiguration,
  isFetchingRatingESG,
  getRatingESG,
  addEsgCertificate,
  vendorVatNumber,
}) => {
  const { t } = useTranslation();

  const [esgCertificate, setEsgCertificate] = React.useState(
    {} as NewEsgCertificateDTO
  );
  const [isClassRating, setIsClassRating] = React.useState(false);
  const [isScoreRating, setisScoreRating] = React.useState(false);
  const [checkCreateEsgCertificate, setCheckEsgCertificate] =
    React.useState(true);
  const [selectedConfiguration, setSelectedConfiguration] =
    React.useState<EsgConfigurationDTO | null>(null);

  const [openCalendars, setOpenCalendars] = React.useState<{
    [key: string]: boolean;
  }>({});

  const handleConfigurationChange = (value: EsgConfigurationDTO) => {
    setSelectedConfiguration(value);
    updateCertificate({
      configurationId: value.id,
      classRating: null,
      scoreRating: null,
    });
    if (value.ratingType === RatingType.CLASS) {
      setIsClassRating(true);
      setisScoreRating(false);
    } else if (value.ratingType === RatingType.SCORE) {
      setisScoreRating(true);
      setIsClassRating(false);
    }
  };

  React.useEffect(() => {
    getRatingESG();
  }, [getRatingESG]);

  const updateCertificate = (inputData: any) => {
    const updatedData = { ...esgCertificate, ...inputData };
    setEsgCertificate(updatedData);
  };

  const handleCalendarOpen = (calendarId: string, isOpen: boolean) => {
    setOpenCalendars((prev) => ({ ...prev, [calendarId]: isOpen }));
  };

  const confirm = () => {
    esgCertificate.configurationId = selectedConfiguration?.id ?? -1;
    esgCertificate.vendorVatNumber = vendorVatNumber;
    setCheckEsgCertificate(false);
  };

  const areDatesValid =
    !esgCertificate.emissionDate || !esgCertificate.expiryDate ||
    moment(esgCertificate.expiryDate).isAfter(esgCertificate.emissionDate);

  const disabledButton =
    !selectedConfiguration ||
    !(esgCertificate.classRating || esgCertificate.scoreRating) ||
    !esgCertificate.base64 ||
    !areDatesValid;

  const acceptedCertificateTypes = [MimeTypes.PDF];

  const closeCombinations = () => {
    setCheckEsgCertificate(true);
    onPrevious();
  };

  return (
    <>
      {checkCreateEsgCertificate && (
        <ScreenWrapper
          title={t("supplier.addEsgCertificate.title")}
          description={t("supplier.addEsgCertificate.description")}
        >
          <CardContainerWithButtons
            title={t("supplier.addEsgCertificate.cardTitle")}
            onPreviousButtonClick={onPrevious}
            onConfirmButtonClick={confirm}
            confirmButtonDisabled={disabledButton}
            error={areDatesValid ? '' : t("supplier.addEsgCertificate.errorDates")}
          >
            <ContainerRow>
              <div className="inputContainer">
                {!isFetchingRatingESG && esgConfiguration && (
                  <MyDropdown
                    links={esgConfiguration}
                    labelExtractor={(item: any) => item.certificationType}
                    inputValue={selectedConfiguration}
                    title={t("supplier.addEsgCertificate.certificateType")}
                    onChange={handleConfigurationChange}
                  />
                )}
              </div>
              <div className="inputContainer">
                {(isClassRating && (
                  <TextInput
                    placeholder={t(
                      "supplier.addEsgCertificate.classRatingPlaceholder"
                    )}
                    title={t("supplier.addEsgCertificate.classRating")}
                    value={esgCertificate.classRating}
                    onChange={(classRating) =>
                      updateCertificate({ classRating })
                    }
                  />
                )) ||
                  (isScoreRating && (
                    <TextInput
                      placeholder={t(
                        "supplier.addEsgCertificate.scoreRatingPlaceholder"
                      )}
                      title={t("supplier.addEsgCertificate.scoreRating")}
                      value={esgCertificate.scoreRating}
                      onChange={(scoreRating) =>
                        updateCertificate({ scoreRating })
                      }
                    />
                  ))}
              </div>
            </ContainerRow>
            <ContainerRow className="addEsgCertificate__datePickerContainer">
              <div className="inputContainer">
                <DatePicker
                  title={t("supplier.addEsgCertificate.emissionDate")}
                  dateInput={esgCertificate.emissionDate}
                  onDateChange={(emissionDate) =>
                    updateCertificate({ emissionDate })
                  }
                  setOpenCalendar={({ focused }) =>
                    handleCalendarOpen(`emissionDate`, focused as boolean)
                  }
                  openCalendar={openCalendars[`emissionDate`] || false}
                />
              </div>
              <div className="inputContainer">
                <DatePicker
                  title={t("supplier.addEsgCertificate.expiryDate")}
                  dateInput={esgCertificate.expiryDate}
                  onDateChange={(expiryDate) =>
                    updateCertificate({ expiryDate })
                  }
                  type={DatePickerTypes.TODAY_AND_FUTURE}
                  setOpenCalendar={({ focused }) =>
                    handleCalendarOpen(`expiryDate`, focused as boolean)
                  }
                  openCalendar={openCalendars[`expiryDate`] || false}
                />
              </div>
            </ContainerRow>
            <ContainerRow>
              <div className="inputContainer">
                <CustomUploadFile
                  title={t("supplier.addEsgCertificate.uploadCertificate")}
                  fileData={esgCertificate.base64}
                  acceptedTypes={acceptedCertificateTypes}
                  onClickUpload={(base64, certificateName) =>
                    updateCertificate({ base64, certificateName })
                  }
                  hasDownload={false}
                />
              </div>
            </ContainerRow>
          </CardContainerWithButtons>
        </ScreenWrapper>
      )}
      {!checkCreateEsgCertificate && (
        <SignWrapper
          actionType={ActionTypes.ESG_CERTIFICATE}
          actionData={esgCertificate}
          referenceId={vendorVatNumber}
          onCloseCombinations={closeCombinations}
        />
      )}
    </>
  );
};

const mapStateToProps = (store: AppState) => {
  return {
    esgConfiguration: store.admin.esgConfiguration,
    isFetchingRatingESG: store.admin.isFetchingRatingESG,
    vendorVatNumber: store.auth?.onBoardingData?.vatNumber,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getRatingESG: () => dispatch(getRatingESG()),
    setCompanyInfoStep: (payload: CompanyInfoSteps) =>
      dispatch(setCompanyInfoStep(payload)),
    addEsgCertificate: (esgCertificate: NewEsgCertificateDTO) =>
      dispatch(addEsgCertificate(esgCertificate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEsgCertificate);
